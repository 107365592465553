console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
// document.addEventListener("touchstart", function(){}, true);

$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});


$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});

$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});

$('#js-header__menu-mobile').click(function(){
  $('#body').toggleClass('is-active-menu');
  $(this).toggleClass('is-active');
  $('.header__layer').toggleClass('is-active');
  if (!$('.header__layer').hasClass('is-active')) {
    $('.header__layer').addClass('no-active');
  } else {
    $('.header__layer').removeClass('no-active');
  }
});


// $('#js-header__menu-mobile').click(function(){
//   if ($('#body').hasClass('is-active-menu')) {
//     $('.header__layer').addClass('e-slide-out-right').one('animationend', function() {
//       $(this).removeClass('e-slide-out-right is-active');
//     });
//   } else {
//     $('.header__layer').addClass('is-active').removeClass('e-slide-out-right');
//   }
//   $('#body').toggleClass('is-active-menu');
//   $(this).toggleClass('is-active');
// });




