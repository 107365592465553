// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@fancyapps/fancybox');


// Scripts
require('./modules/scripts');



	









